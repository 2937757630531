import 'alpinejs'
import Turbolinks from 'turbolinks'
import lunr from 'lunr'

Turbolinks.start()

window.search = () => ({
  results: [],
  query: '',
  focussed: false,

  onFocus() {
    this.focussed = true
    if (!this.idx) {
      fetch('/search-index.json')
        .then(response => 
          response.json()
        ).then(json => {
          const {idx, display} = json
          this.idx = lunr.Index.load(idx)
          this.display = display
          this.search() // In case user types before index is loaded
        })
    }
  },

  onBlur(ev) {
    if (ev.relatedTarget?.tagName != 'A') {
      this.focussed = false
    }
  },

  showResults() {
    return this.focussed && this.results.length > 0
  },

  search() {
    const {idx, query} = this
    if (idx && query.length > 0) {
      const results = idx.search(query).slice(0,10)
      this.results = results.map(r => ({
        label: this.display[r.ref].label,
        klass: `block fas w-8 text-base flex-shrink-0 text-center fa-${this.display[r.ref].icon} text-gray-500 relative top-px `,
        href: `/pages/${r.ref}`
      }))
    } else {
      this.results = []
    }
  }

})

window.netlifyDebug = async function() {
  return "Nope"
}
